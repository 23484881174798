import React from 'react'
import Page from '../components/Page'

const PatentInfrigementServicesPages = () => (
  <Page title="PATENT INFRINGEMENT SERVICES">
    <p className="mt-8 w-2/3 text-center">
      We assist our customers in:<br/>
      <br/>
    </p>
    <ul className="w-1/2">
      <li className="p-4 mb-2 bg-main">reviewing the allegedly infringed patent</li>
      <li className="p-4 mb-2 bg-main">providing the alleged patent infringer with the first TODOs to react to patent proprietor's action</li>
      <li className="p-4 mb-2 bg-main">providing the patent proprietor with the first TODOs to proceed against an alleged patent infringer</li>
      <li className="p-4 mb-2 bg-main">performing infringement analysis</li>
      <li className="p-4 mb-2 bg-main">delivering and reviewing claim charts on the patent infringement object</li>
      <li className="p-4 mb-2 bg-main">developing and elaborating infringement/non-infringement arguments</li>
    </ul>
  </Page>
)

export default PatentInfrigementServicesPages